import React, { useEffect, useState } from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { Flex, Box, Text, FormControl, FormLabel, Input, Stack, Button } from '@chakra-ui/react'
import { useAuth } from '../context/auth'
import { translate } from '../utils/firebaseError'

const LoginPage: NextPage = () => {
	const { login, isLoading, isAuthenticated, error } = useAuth()
	const router = useRouter()

	const [username, setUsername] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleUsernameChange = (event: any) => setUsername(event.target.value)
	const handlePasswordChange = (event: any) => setPassword(event.target.value)
	const handleSubmit = async () => await login(email, password)

	useEffect(() => {
		setEmail(username + '@nagataku.com')
	}, [username])

	useEffect(() => {
		if (isAuthenticated) {
			router.push('/')
		}
	}, [isAuthenticated])

	return (
		<Flex minH="100vh" align="center" justify="center">
			<Stack spacing={8} mx={'auto'} w="sm" py={12} px={6}>
				{/* <Stack align={'center'}>
					<Heading fontSize={'4xl'}>Sign in to your account</Heading>
					<Text fontSize="lg" color={'gray.600'}>
						to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
          </Text>
				</Stack> */}
				<Box rounded="lg" boxShadow="lg" p={8}>
					<Stack spacing={4}>
						<FormControl id="username">
							<FormLabel>ユーザー名</FormLabel>
							<Input type="username" value={username} onChange={handleUsernameChange} />
						</FormControl>
						<FormControl id="password">
							<FormLabel>パスワード</FormLabel>
							<Input type="password" value={password} onChange={handlePasswordChange} />
						</FormControl>
						<Stack spacing={10}>
							{/* <Stack
								direction={{ base: 'column', sm: 'row' }}
								align={'start'}
								justify={'space-between'}>
								<Checkbox>Remember me</Checkbox>
								<Link color={'blue.400'}>Forgot password?</Link>
							</Stack> */}
							{error && (
								<Text fontSize="sm" color="red">
									{translate(error)}
								</Text>
							)}
							<Button colorScheme="teal" onClick={handleSubmit} mt={4} isLoading={isLoading}>
								サインイン
							</Button>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	)
}

export default LoginPage
